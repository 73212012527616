.chatbot-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 75px;
    height: 75px;
    background-color: #3d728d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
  }
  
  .chatbot-icon i {
    font-size: 24px;
  }
  
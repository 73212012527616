.chatbox-container {
    position: fixed;
    z-index: 444;
    bottom: 80px;
    right: 20px;
    width: 300px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    display: 1;
    flex-direction: column;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.chatbox-container.minimized {
    width: 0px; /* Adjust the width of the minimized window */
    height: 0px; /* Adjust the height of the minimized window */
    right: 20px; /* Adjust the right offset as desired */
    bottom: 20px; /* Adjust the bottom offset as desired */
    background-color: #2d819d;
}

.minimize-button {
    position: absolute;
    top: 1.5%;
    right: 10px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.send-button {
    width: 75px;
    height: 35px;
}

.send-button:disabled {
    background-color: #666;
}
  
.chatbox-container.minimized .chatbox-avatar {
    display: none; /* Hide the avatar when minimized */
}

.chatbox-header {
    padding: 10px;
    background-color: #509eb8;
    color: #ffffff;
    border-radius: 15px 15px 0px 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #c8c8c8;
}
  
.chatbox-header h3 {
    margin: 0;
    font-size: 16px;
}
  
.chatbox-messages {
    flex: 1 1;
    padding: 6px 0px 6px 6px;
    height: 400px;
    overflow-y: scroll;
    margin: 0;
}

.chatbox-messages::-webkit-scrollbar {
    width: 6px;
}

.chatbox-messages::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.chatbox-messages::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.chatbox-messages p {
    margin: 5px 0;
}

.chatbox-messages .user-message {
    text-align: left;
    color: #ffffff;
    background-color: #62b1cb;
    border-radius: 25px;
    padding: 15px;
    margin-left: 80px;
    font-size: 0.95rem;
    margin-right: 0px;
}

.chatbox-messages .user-message:first-letter {
    text-transform: uppercase;
}

.chatbox-messages .system-message {
    justify-content: flex-start;
    text-align: left;
    color: #fff;
    border-radius: 25px;
    background-color: #90d7db26;
    padding: 20px;
    margin: 10px;
    font-size: 0.95rem;
    margin-right: 45px;
    margin-left: 0px;
}

.chatbox-messages .user-message .message-box {
    color: white; /* User message text color */
    margin-left: auto; /* Move user message box to the right */
    margin-right: 0;
}

.chatbox-messages .system-message .message-box {
    color: #555; /* System message text color */
    margin-left: 0; /* Move system message box to the left */
    margin-right: auto;
}

.chatbox-messages .system-message strong {
    font-weight: bold;
}

.chatbox-messages .system-message em {
    font-style: italic;
}

.chatbox-messages .system-message code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
}

.chatbox-container form {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.chatbox-container input[type='text'] {
    flex: 1 1;
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 35px;
}

.chatbox-container button {
    background-color: #2d819d;
    color: white;
    border: none;
    padding: 2px 13px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}